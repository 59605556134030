import PropTypes from 'prop-types'
import * as React from 'react';
import {Popup} from '@partssourceinc/react-ui-core';
import TextFieldApiSelect, {ApiSelectType} from 'components/TextFieldApiSelect';

export default class ContactSearchDialog extends React.Component {
    static propTypes = {
        onCancel: PropTypes.func,
        onSubmit: PropTypes.func,
        adminSearch: PropTypes.bool,
        disableClose: PropTypes.bool,
    };

    constructor(props) {
        super(props);
    }

    closePopup() {
        const {disableClose, onCancel} = this.props;
        if (disableClose)
            return;
        
        onCancel();
    }

    onSelectContact(contact) {
        const {onSubmit} = this.props;
        onSubmit(contact);
    }

    render() {
        const {disableClose} = this.props;

        return (
            <Popup confirmText="Save"
                cancelText="Cancel"
                show={true}
                hideButtons={true}
                onCancel={::this.closePopup}
                className="facility-dialog"
                disableClose={disableClose}
            >
                <div>
                    <h1>Select a User</h1>
                    <TextFieldApiSelect
                        keyField="contactId"
                        valueField="searchDisplayName"
                        onSelect={::this.onSelectContact}
                        listItems={[]}
                        label="User"
                        placeHolder="User"
                        autoExpand={true}
                        selectType={ApiSelectType.Contact} />
                </div>
            </Popup>
        );
    }
}
