import * as React from 'react';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import * as ReactRouterPropTypes from 'react-router-prop-types';
import _ from 'lodash';
import FacilitySearchDialog from 'components/FacilitySearchDialog';
import * as SettingsStore from 'stores/Settings';
import * as NetworkStore from 'stores/Network';
import Cookies from 'js-cookie';
import 'less/Admin/adminHeader.less';

@withRouter
@connect((state) => ({network: state.network, user: state.user, settings: state.settings}), _.merge({}, NetworkStore.actionCreators, SettingsStore.actionCreators))
export class AdminHeader extends React.Component {
  static displayName = 'AdminHeader';

  static propTypes = {
      ...ReactRouterPropTypes,
      ...NetworkStore.ActionShape,
      ...SettingsStore.ActionShape,
      network: NetworkStore.StateShape,
      settings: SettingsStore.StateShape,
  };

  constructor(props) {
      super(props);

      this.state = {
          showFacilityDialog: false,
      };
  }

  componentDidMount() {
      const {settings: {facility}, loadInitialAdminData} = this.props;

      if (facility && facility.facilityId > 0)
          loadInitialAdminData();
  }

  onCloseFacility() {
      const {settings: {facility}, history} = this.props;

      if (!facility.facilityId > 0) {
          history.push('/admin/company');
      }

      this.setState({showFacilityDialog: false});
  }

  onSelectFacility(facility) {
      const {saveReduxSettings, settings, loadInitialAdminData} = this.props;
      settings.facility = facility;
      saveReduxSettings(settings);
      const isNotLocalhost = window.location.hostname !== 'localhost';
      Cookies.set('employeeFacility', JSON.stringify(facility), 
          {
              'secure': isNotLocalhost, 
              sameSite: isNotLocalhost ? 'None' : undefined,
          });
      loadInitialAdminData().then(() => {
          this.setState({showFacilityDialog: false});
      });
  }

  render() {
      const {showFacilityDialog} = this.state;
      const {network: {admin: {permissions}}, settings: {facility}, location: {pathname}} = this.props;

      let openFacility = showFacilityDialog || (pathname.indexOf('/admin/company/') > -1 && !facility.facilityId > 0) || (pathname.indexOf('/admin/dataconfig') > -1 && !facility.facilityId > 0);

      let showFacility = pathname.indexOf('/admin/company') > -1 || pathname.indexOf('/admin/dataconfig') > -1;
      let logoLink = '/admin';
      if (permissions?.requestForQuoteManger) logoLink = '/admin/rfq';
      if (permissions?.contractOrderManager) logoLink = '/admin/contractPro/orders-overview';
      if (permissions?.contractManager) logoLink = '/admin/company/contracts';

      return (<div>
          <div>
              <div className="row admin-header" />
              <div className="row admin-header">
                  <div className="col-sm-8 second-header-row">
                      <NavLink to={logoLink}>
                          <img src="/images/partssourcelogo300.png" />
                      </NavLink>
                      <span className="title">The World’s Largest Provider of Medical Replacement Products</span>
                  </div>
                  <div className="col-sm-4" style={{height: '74px'}}>
                      {showFacility && <span className="facility-wrapper">
                          <span className="facility" onClick={() => this.setState({showFacilityDialog: true})}>
                              <i className="fa fa-map-marker" />
                              <span className={facility.facilityName ? 'facility-label' : 'facility-label empty'}>Facility</span>
                              <span className={facility.facilityName ? 'facility-name' : 'facility-name empty'}>
                                  {facility.facilityName ? facility.facilityName : 'Select a Facility'}
                              </span>
                          </span>
                      </span>}
                  </div>
              </div>
          </div>
          {openFacility ? <FacilitySearchDialog adminSearch={true} onSubmit={::this.onSelectFacility} onCancel={::this.onCloseFacility} /> : null}
      </div >);
  }
}
