import * as PropTypes from 'prop-types';
import * as React from 'react';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { NavLink, Route, Switch } from 'react-router-dom';
import * as ReactRouterPropTypes from 'react-router-prop-types';
import _ from 'lodash';
import { AdminHeader } from 'components/Admin/AdminHeader';
import ContactSearchDialog from 'components/ContactSearchDialog';
import Footer from 'components/Footer';
import PopupAdmin from 'components/PopupAdmin';
import * as NetworkStore from 'stores/Network';
import { AdminRole } from 'stores/Network';
import * as UserStore from 'stores/User';
import * as SettingsStore from 'stores/Settings';
import * as CartStore from 'stores/Cart';
import * as DashboardStore from 'stores/Dashboard';
import * as SystemStore from 'stores/System';
import { clearTokenInfo, translateHomeScreen } from 'utility';
import Spinner from 'components/Spinner';
import { AuthHandlerContext } from 'contexts/AuthHanderContext';

import 'less/libs.css';
import 'less/admin/adminlayout.less';
function Loading(props) {
    const { error } = props;
    if (error && location.hostname !== 'localhost') {
        location.reload(true);
    }
    return null;
}

Loading.propTypes = {
    error: PropTypes.any,
};

const AdminSettings = Loadable({loader: () => import(/* webpackChunkName: "AdminSettings" */'pages/Admin/AdminSettings'), loading: Loading});
const CompanyManagement = Loadable({loader: () => import(/* webpackChunkName: "CompanyManagement" */'components/Settings/CompanyManagement'), loading: Loading});
const CompanyPrograms = Loadable({loader: () => import(/* webpackChunkName: "CompanyManagement" */'components/Settings/CompanyPrograms'), loading: Loading});
const GroupManagement = Loadable({loader: () => import(/* webpackChunkName: "GroupManagement" */'components/Settings/GroupManagement'), loading: Loading});
const UserManagement = Loadable({loader: () => import(/* webpackChunkName: "UserManagement" */'components/Settings/UserManagement'), loading: Loading});
const CompanySettings = Loadable({loader: () => import(/* webpackChunkName: "CompanySettings" */'components/Settings/CompanySettings'), loading: Loading});
const ContractProOrderOverview = Loadable({loader: () => import(/* webpackChunkName: "ContractProOrderDetails" */'pages/ContractProOrderOverview'), loading: Loading});
const ContractProOrderDetails = Loadable({loader: () => import(/* webpackChunkName: "ContractProOrderDetails" */'pages/ContractProOrderDetails'), loading: Loading});
const PaymentMethods = Loadable({loader: () => import(/* webpackChunkName: "PaymentMethods" */'components/Settings/PaymentMethods'), loading: Loading});
const CompanySubscriptions = Loadable({loader: () => import(/* webpackChunkName: "CompanySubscriptions" */'components/Settings/CompanySubscriptions'), loading: Loading});
const ApprovalSettings = Loadable({loader: () => import(/* webpackChunkName: "ApprovalSettings" */'components/Settings/ApprovalSettings'), loading: Loading});
const CustomCatalogRules = Loadable({loader: () => import(/* webpackChunkName: "CustomCatalogRules" */'components/Settings/CustomCatalogRules'), loading: Loading});
const RavenManagement = Loadable({loader: () => import(/* webpackChunkName: "RavenManagement" */'components/Settings/RavenManagement'), loading: Loading});
const AlertManagement = Loadable({loader: () => import(/* webpackChunkName: "AlertManagement" */'components/Settings/AlertManagement'), loading: Loading});
const AlertManagementEdit = Loadable({loader: () => import(/* webpackChunkName: "AlertManagementEdit" */'components/Settings/AlertManagementEdit'), loading: Loading});
const FormularyManagement = Loadable({loader: () => import(/* webpackChunkName: "FormularyManagement" */'components/Settings/FormularyManagement'), loading: Loading});
const FormularyLists = Loadable({loader: () => import(/* webpackChunkName: "FormularyLists" */'components/Settings/FormularyLists'), loading: Loading});
const FormularyListDetail = Loadable({loader: () => import(/* webpackChunkName: "FormularyListDetail" */'components/Settings/FormularyListDetail'), loading: Loading});
const FormularyRules = Loadable({loader: () => import(/* webpackChunkName: "FormularyRules" */'components/Settings/FormularyRules'), loading: Loading});
const FormularyRuleDetail = Loadable({loader: () => import(/* webpackChunkName: "FormularyRuleDetail" */'components/Settings/FormularyRuleDetail'), loading: Loading});
const RFQPDPManagement = Loadable({loader: () => import(/* webpackChunkName: "RFQLimitManagement" */'components/Settings/RFQPDPManagement'), loading: Loading});
const ProductSubgroupManagement = Loadable({loader: () => import(/* webpackChunkName: "ProductSubgroupManagement" */'components/Settings/ProductSubgroupManagement'), loading: Loading});
const AutoPricingManagement = Loadable({loader: () => import(/* webpackChunkName: "ProductSubgroupManagement" */'components/Settings/AutoPricingManagement'), loading: Loading});
const VendorShippingMarginManagement = Loadable({loader: () => import(/* webpackChunkName: "VendorShippingMarginManagement" */'components/Settings/VendorShippingMarginManagement'), loading: Loading});
const VendorShippingMarginManagementManageRule = Loadable({loader: () => import(/* webpackChunkName: "VendorShippingMarginManagement" */'components/Settings/VendorShippingMarginManagementManageRule'), loading: Loading});
const PurchaseContracts = Loadable({loader: () => import(/* webpackChunkName: "PurchaseContracts" */'components/Settings/PurchaseContracts'), loading: Loading});
const ManageContracts = Loadable({loader: () => import(/* webpackChunkName: "PurchaseContracts" */'components/Settings/ManageContracts'), loading: Loading});
const DomainWhiteListManagement = Loadable({loader: () => import(/* webpackChunkName: "PurchaseContracts" */'components/Settings/DomainWhiteListManagement'), loading: Loading});
const ManageContractsList = Loadable({loader: () => import(/* webpackChunkName: "PurchaseContracts" */'components/Settings/ManageContractsList'), loading: Loading});
const RFQManagement = Loadable({loader: () => import(/* webpackChunkName: "RFQManagement" */'components/Settings/RFQManagement'), loading: Loading});
const RFQServiceManagement = Loadable({loader: () => import(/* webpackChunkName: "RFQManagement" */'components/Settings/RFQServiceManagement'), loading: Loading});
const RFQHeaderManagement = Loadable({loader: () => import(/* webpackChunkName: "RFQManagement" */'components/Settings/RFQHeaderManagement'), loading: Loading});
const OneSourceOemMapping = Loadable({loader: () => import(/* webpackChunkName: "OneSourceOemMapping" */'pages/OneSourceOemMapping'), loading: Loading});
const SiteSettings = Loadable({loader: () => import(/* webpackChunkName: "SiteSettings" */'components/Settings/SiteSettings'), loading: Loading});
const RSImportTool = Loadable({loader: () => import(/* webpackChunkName: "RSImportTool" */'components/Settings/RSImportTool'), loading: Loading});
const AdminLists = Loadable({loader: () => import(/* webpackChunkName: "AdminLists" */'pages/AdvancedLists/AdminLists'), loading: Loading});
const AdminSaveAdvancedList = Loadable({loader: () => import(/* webpackChunkName: "AdminSaveAdvancedList" */'pages/AdvancedLists/AdminSaveAdvancedList'), loading: Loading});
const SalesRegionManagement = Loadable({loader: () => import(/* webpackChunkName: "PurchaseContracts" */'components/Settings/SalesRegionManagement'), loading: Loading});
const ReportingPortalManagement = Loadable({loader: () => import(/* webpackChunkName: "PurchaseContracts" */'components/Settings/ReportingPortalManagement'), loading: Loading});
const RPWidget = Loadable({loader: () => import(/* webpackChunkName: "PurchaseContracts" */'components/Settings/RPWidgetManagement'), loading: Loading});
const RPPortal = Loadable({loader: () => import(/* webpackChunkName: "PurchaseContracts" */'components/Settings/RPPortalManagement'), loading: Loading});
const RPWidgetAdd = Loadable({loader: () => import(/* webpackChunkName: "PurchaseContracts" */'components/Settings/RPWidgetAdd'), loading: Loading});
const RPPortalAdd = Loadable({loader: () => import(/* webpackChunkName: "PurchaseContracts" */'components/Settings/RPPortalAdd'), loading: Loading});
const RequiredFields = Loadable({loader: () => import('components/Settings/RequiredFields'), loading: Loading});
const RequiredFieldsVendor = Loadable({loader: () => import('components/Settings/RequiredFieldsVendor'), loading: Loading});
const RequiredFieldsCustomer = Loadable({loader: () => import('components/Settings/RequiredFieldsCustomer'), loading: Loading});
const RequiredFieldsInvoice = Loadable({loader: () => import('components/Settings/RequiredFieldsInvoice'), loading: Loading});
const ManageFields = Loadable({loader: () => import('components/Settings/ManageFields'), loading: Loading});
const ManageInvoiceFields = Loadable({loader: () => import('components/Settings/ManageInvoiceFields'), loading: Loading});
const CertificationManagement = Loadable({loader: () => import('components/Settings/CertificationManagement'), loading: Loading});
const AdminFormularyChangesGrid = Loadable({loader: () => import(/* webpackChunkName: "AdminFormularyChangesGrid" */'../VisualFormulary/AdminFormularyChangesGrid'), loading: Loading});
const TestFormulary = Loadable({loader: () => import(/* webpackChunkName: "TestFormulary" */'../VisualFormulary/TestFormulary'), loading: Loading});
const ProgramsManagement = Loadable({loader: () => import(/* webpackChunkName: "CompanyManagement" */'components/Settings/ProgramsManagement'), loading: Loading});
const TeamContactCenter = Loadable({loader: () => import('components/Settings/TeamContactCenter'), loading: Loading});
const ShoppingFeedConfiguration = Loadable({loader: () => import(/* webpackChunkName: "ShoppingFeedConfiguration" */'components/Settings/ShoppingFeedConfiguration'), loading: Loading});
const TeamContactCenterAccountManager = Loadable({loader: () => import('components/Settings/TeamContactCenterAccountManager'), loading: Loading});
const DataConfiguration = Loadable({loader: () => import('components/Settings/DataConfiguration/DataConfiguration'), loading: Loading});
const DataConfigurationList = Loadable({loader: () => import('components/Settings/DataConfiguration/DataConfigurationList'), loading: Loading});
const DataConfigurationOrders = Loadable({loader: () => import('components/Settings/DataConfiguration/DataConfigurationOrders'), loading: Loading});
const DataConfigurationRepairs = Loadable({loader: () => import('components/Settings/DataConfiguration/DataConfigurationRepairs'), loading: Loading});
const DataConfigurationListDetails = Loadable({ loader: () => import('components/Settings/DataConfiguration/DataConfigurationListDetails/DataConfigurationListDetails'), loading: Loading });
const DataConfigurationQuotes = Loadable({loader: () => import('components/Settings/DataConfiguration/DataConfigurationQuotes'), loading: Loading});
const DataConfigurationReturns = Loadable({loader: () => import('components/Settings/DataConfiguration/DataConfigurationReturns'), loading: Loading});
const DataConfigurationUserSettings = Loadable({loader: () => import('components/Settings/DataConfiguration/DataConfigurationUserSettings'), loading: Loading});
const DataConfigurationExchanges = Loadable({loader: () => import('components/Settings/DataConfiguration/DataConfigurationExchanges'), loading: Loading});
const DataConfigurationLoaners = Loadable({loader: () => import('components/Settings/DataConfiguration/DataConfigurationLoaners'), loading: Loading});
const DataConfigurationQuotedRepairs = Loadable({loader: () => import('components/Settings/DataConfiguration/DataConfigurationQuotedRepairs'), loading: Loading});


@withRouter
@connect(
    (state) => ({
        network: state.network,
        cart: state.cart,
        settings: state.settings,
        configuration: state.system.configuration,
    }),
    _.merge({},
        NetworkStore.actionCreators,
        UserStore.actionCreators,
        SettingsStore.actionCreators,
        DashboardStore.actionCreators,
        SystemStore.actionCreators
    )
)
class AdminLayout extends React.Component {
    static contextType = AuthHandlerContext;
    static propTypes = {
        ...ReactRouterPropTypes,
        ...NetworkStore.ActionShape,
        ...UserStore.ActionShape,
        ...DashboardStore.ActionShape,
        network: NetworkStore.StateShape,
        cart: CartStore.StateShape,
        settings: SettingsStore.StateShape,
        configuration: SystemStore.StateShape,
    };

    constructor(props) {
        super(props);

        this.state = {
            showContactDialog: false,
            showImpersonationError: false,
            impersonationErrorMsg: '',
            impersonating: false,
        };
    }

    componentDidMount() {
        const { network: { admin: { isLoggedIn, userId, permissions } }, history, loadFormularyLists, loadRfqRules } = this.props;

        if (!isLoggedIn || !userId > 0)
            return;

        loadFormularyLists();
        loadRfqRules();

        if (location.pathname.indexOf('/admin/raven') === 0 && !permissions?.fullAdmin) {
            history.push('/admin');
        }
        if (location.pathname !== '/admin/rfq' && permissions?.requestForQuoteManager) {
            history.push('/admin/rfq');

            if (location.pathname !== '/admin/psm' && permissions?.requestForQuoteManager) {
                history.push('/admin/psm');

                if (location.pathname !== '/admin/apm' && permissions?.requestForQuoteManager) {
                    history.push('/admin/apm');
                }
            }
        }
        if (location.pathname !== '/admin/contractPro/orders-overview' && permissions?.contractOrderManager) {
            history.push('/admin/contractPro/orders-overview');
        }
        if (location.pathname != '/admin/company/contracts' && permissions?.contractManager) {
            history.push('/admin/company/contracts');
        }

        if ((location.pathname.indexOf('/admin/vsm') === 0 || location.pathname.indexOf('/admin/vsm-add-edit')) === 0 &&
            !permissions?.fullAdmin && !permissions?.variableShipMargin_Dev && !permissions?.variableShipMargin_Prod) {
            history.push('/admin');
        }

        if ((location.pathname.indexOf('/admin/alert-management') === 0 || location.pathname.indexOf('/admin/alert-management/edit')) === 0 &&
            !permissions?.fullAdmin && !permissions?.priorityOrderAlertAdmin_Dev && !permissions?.priorityOrderAlertAdmin_Prod) {
            history.push('/admin');
        }

        if (document) document.title = 'Administration : PartsSource - Healthcare Products and Solutions';
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const { props } = this;
        if (props.location.pathname !== nextProps.location.pathname) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    logOut = () => {
        const { history, stopNotifications } = this.props;
        stopNotifications();
        clearTokenInfo();
        const { context } = this;
        context.triggerLogout();
        history.push('/');
    }

    impersonateUser = (user) => {
        const { impersonateUser, getSettings, network: { sessionStorageAvailable } } = this.props;
        this.setState({ impersonating: true, showContactDialog: false });
        impersonateUser(user.contactId).then(x => {
            if (!x.response.data || x.response.data.error) {
                this.setState({
                    showContactDialog: false,
                    showImpersonationError: true,
                    impersonating: false,
                    impersonationErrorMsg: `${user.searchDisplayName} doesn't have access to Apollo.`,
                });
            } else {
                getSettings().then(action => {
                    if (sessionStorageAvailable)
                        sessionStorage.removeItem('fields');
                    location.href = translateHomeScreen(action.response.data.settings.defaultHomeScreen);
                })
            }
        });
    }

    linkClass(path) {
        const { location } = this.props;
        return location.pathname === path ? 'selected' : '';
    }

    render() {
        const { showContactDialog, showImpersonationError, impersonationErrorMsg, impersonating } = this.state;
        const { network: { admin: { firstName, lastName, role, permissions } } } = this.props;

        const contactCenterRoles = permissions?.fullAdmin || permissions?.readOnly || permissions?.customerTeamContactCenterAdmin;
        const alertManagementDevRoles = permissions?.fullAdmin || permissions?.alertManagementAdmin_Dev;
        const alertManagementProdRoles = permissions?.fullAdmin || permissions?.alertManagementAdmin_Prod;
        const prodEnvironment = process.env.REACT_APP_ENVIRONMENT === 'production';

        return (
            <div id="divBodyContainer" className="container-fluid">
                <AdminHeader />
                {impersonating && <Spinner />}
                <div className="admin-left-column">
                    <div className="admin-user-panel">
                        <div className="hello">Hello,</div>
                        <div className="name">
                            {_.startCase((firstName || '').toLowerCase())} {_.startCase((lastName || '').toLowerCase())}
                        </div>
                        <div>
                            {(permissions?.fullAdmin || role == AdminRole.Impersonation) && <React.Fragment>
                                <span
                                    className="authentication-link"
                                    onClick={() => this.setState({ showContactDialog: true })}
                                >
                                    Impersonate User
                                </span>
                                <span className="authentication-spacer">|</span>
                            </React.Fragment>}
                            <span className="authentication-link" onClick={this.logOut}>Sign Out</span>

                        </div>
                    </div>
                    <div className="admin-menu-links">
                        {!permissions?.requestForQuoteManager && !permissions?.contractOrderManager && !permissions?.contractManager && <React.Fragment>
                            <NavLink to="/admin/company" className={this.linkClass('/admin/company')}>Company Management</NavLink>
                            <span className="sub">
                                <NavLink to="/admin/company/users" className={this.linkClass('/admin/company/users')}>User Management</NavLink>
                                <NavLink to="/admin/company/groups" className={this.linkClass('/admin/company/groups')}>Group Management</NavLink>
                                <NavLink to="/admin/company/settings" className={this.linkClass('/admin/company/settings')}>Company Settings</NavLink>
                                <NavLink to="/admin/company/payment" className={this.linkClass('/admin/company/payment')}>Payment Methods</NavLink>
                                <NavLink to="/admin/company/approvals" className={this.linkClass('/admin/company/approvals')}>Approvals</NavLink>
                                <NavLink to="/admin/company/contracts" className={this.linkClass('/admin/company/contracts')}>Contract Management</NavLink>
                                <NavLink to="/admin/company/subscriptions" className={this.linkClass('/admin/company/subscriptions')}>Account Configuration</NavLink>
                                {contactCenterRoles && <NavLink to="/admin/company/teamcontactcenter" className={this.linkClass('/admin/company/teamcontactcenter')}>Team Contact Center</NavLink>}
                            </span>
                            <NavLink to="/admin/contractPro/orders-overview" className={this.linkClass('/admin/contractPro/orders-overview')}>ContractPro Orders Overview</NavLink>
                            <NavLink to="/admin/programs" className={this.linkClass('/admin/programs')}>PartsSource+ Program</NavLink>
                            <span className="sub">
                                <NavLink to="/admin/programs/list" className={this.linkClass('/admin/programs/list')}>Program List</NavLink>
                            </span>
                            {(alertManagementDevRoles || alertManagementProdRoles) && <NavLink to="/admin/alert-management" className={this.linkClass('/admin/alert-management')}>Alert Management</NavLink>}
                            <NavLink to="/admin/formulary" className={this.linkClass('/admin/formulary')}>Formulary</NavLink>
                            <span className="sub">
                                <NavLink to="/admin/formulary/lists" className={this.linkClass('/admin/formulary/lists')}>Formulary Lists</NavLink>
                                <NavLink to="/admin/company/formulary" className={this.linkClass('/admin/company/formulary')}>Formulary Management</NavLink>
                                <NavLink to="/admin/formulary/drafts" className={this.linkClass('/admin/formulary/drafts')}>Formulary Changes</NavLink>
                            </span>
                            <NavLink to="/admin/rfq" className={this.linkClass('/admin/rfq')}>RFQ Management</NavLink>
                            <span className="sub">
                                <NavLink to="/admin/rfq/service" className={this.linkClass('/admin/rfq/service')}>Service RFQ Management</NavLink>
                                <NavLink to="/admin/rfq/pdp" className={this.linkClass('/admin/rfq/pdp')}>PDP RFQ Limit Management</NavLink>
                                <NavLink to="/admin/rfq/header" className={this.linkClass('/admin/rfq/header')}>RFQ Header Management</NavLink>
                            </span>
                        </React.Fragment>}
                        {(permissions?.fullAdmin) &&
                            <React.Fragment>
                                <NavLink to="/admin/rp" className={this.linkClass('/admin/rp')}>Reporting Portal Management</NavLink>
                                <span className="sub">
                                    <NavLink to="/admin/rp/widget" className={this.linkClass('/admin/rp/widget')}>PS Portal Widget Management</NavLink>
                                    <NavLink to="/admin/rp/portal" className={this.linkClass('/admin/rp/portal')}>PS Portal Management</NavLink>
                                </span>
                            </React.Fragment>}
                        {(permissions?.fullAdmin || permissions?.vendorReqAdmin_Dev || permissions?.vendorReqAdmin_Prod || permissions?.customerReqAdmin_Dev || role === permissions?.customerReqAdmin_Prod) &&
                            <React.Fragment>
                                <NavLink to="/admin/required-fields" className={this.linkClass('/admin/required-fields')}>Required Fields</NavLink>
                                <span className="sub">
                                    {(permissions?.fullAdmin || permissions?.vendorReqAdmin_Dev || permissions?.vendorReqAdmin_Prod) && <NavLink to="/admin/required-fields/vendor" className={this.linkClass('/admin/required-fields/vendor')}>Vendor Required Fields</NavLink>}
                                    {(permissions?.fullAdmin || permissions?.customerReqAdmin_Dev || permissions?.customerReqAdmin_Prod) && <NavLink to="/admin/required-fields/customer" className={this.linkClass('/admin/required-fields/customer')}>Customer Required Fields</NavLink>}
                                    {(permissions?.fullAdmin || permissions?.customerReqAdmin_Dev || permissions?.customerReqAdmin_Prod) && <NavLink to="/admin/required-fields/invoice" className={this.linkClass('/admin/required-fields/invoice')}>Customer Invoice Fields</NavLink>}
                                </span>
                            </React.Fragment>}
                        {(permissions?.fullAdmin) &&
                            <NavLink to="/admin/vendor-cert" className={this.linkClass('/admin/site-settings')}>Certification Management</NavLink>
                        }
                        <NavLink to="/admin/site-settings" className={this.linkClass('/admin/site-settings')}>Site Settings</NavLink>
                        <NavLink to="/admin/psm" className={this.linkClass('/admin/psm')}>Product Subgroup Management</NavLink>
                        <NavLink to="/admin/apm" className={this.linkClass('/admin/apm')}>Auto Pricing Management</NavLink>
                        {(permissions?.fullAdmin || permissions?.variableShipMargin_Dev || permissions?.variableShipMargin_Prod) &&
                            <NavLink to="/admin/vsm" className={this.linkClass('/admin/vsm')}>Shipping Margin Management</NavLink>
                        }
                        {permissions?.fullAdmin && <NavLink to="/admin/raven" className={this.linkClass('/admin/raven')}>Raven Management</NavLink>}
                        {permissions?.contractOrderManager && <NavLink to="/admin/contractPro/orders-overview" className={this.linkClass('/admin/contractPro/orders-overview/')}>ContractPro Orders Overview</NavLink>}
                        {permissions?.contractManager && <NavLink to="/admin/company/contracts" className={this.linkClass('/admin/company/contracts')}>Contract Management</NavLink>}
                        <NavLink to="/admin/onesource-oem-mapping" className={this.linkClass('/admin/onesource-oem-mapping')}>oneSOURCE OEM Mapping</NavLink>
                        <NavLink to="/admin/rsimport" className={this.linkClass('/admin/rsimport')}>RS Import Tool</NavLink>
                        <NavLink to="/admin/lists" className={this.linkClass('/admin/lists')}>Adv. List Management</NavLink>
                        <NavLink to="/admin/domainwhitelist" className={this.linkClass('/admin/domainwhitelist')}>Domain Whitelist</NavLink>
                        <NavLink to="/admin/salesregions" className={this.linkClass('/admin/salesregions')}>Sales Reg. Assign. Management</NavLink>
                        <NavLink to="/admin/shoppingfeed/configuration" className={this.linkClass('/admin/shoppingfeed/configuration')}>Shopping Feed Configuration</NavLink>
                        {!prodEnvironment && 
                        <React.Fragment>
                            <NavLink to="/admin/dataconfig" classname={this.linkClass('/admin/dataconfig')}>Data Configuration</NavLink>
                            <span className="sub">
                                <NavLink to="/admin/dataconfig/user-settings" className={this.linkClass('/admin/dataconfig/user-settings')}>User Settings</NavLink>
                                <NavLink to="/admin/dataconfig/userlists" className={this.linkClass('/admin/dataconfig/userlists')}>User Lists</NavLink>
                                <NavLink to="/admin/dataconfig/quotes" className={this.linkClass('/admin/dataconfig/quotes')}>Quotes</NavLink>
                                <NavLink to="/admin/dataconfig/partorders" className={this.linkClass('/admin/dataconfig/partorders')}>Part Orders</NavLink>
                                <NavLink to="/admin/dataconfig/repairs" className={this.linkClass('/admin/dataconfig/repairs')}>Depot Repairs</NavLink>
                                <NavLink to="/admin/dataconfig/quotedrepairs" className={this.linkClass('/admin/dataconfig/quotedrepairs')}>Quoted Repairs</NavLink>
                                <NavLink to="/admin/dataconfig/exchanges" className={this.linkClass('/admin/dataconfig/exchanges')}>Exchanges</NavLink>
                                <NavLink to="/admin/dataconfig/returns" className={this.linkClass('/admin/dataconfig/returns')}>Returns</NavLink>
                                <NavLink to="/admin/dataconfig" className={this.linkClass('/admin/dataconfig')}>Loaners</NavLink>
                            </span>
                        </React.Fragment>}
                    </div>
                </div>
                <div className="admin-right-column">
                    <main role="main" className="admin-body-content">
                        <Switch>
                            <Route exact={true} path="/admin" component={AdminSettings} />
                            <Route exact={true} path="/admin/company" component={CompanyManagement} />
                            <Route exact={true} path="/admin/programs" component={ProgramsManagement} />
                            <Route exact={true} path="/admin/programs/list" component={CompanyPrograms} />
                            <Route exact={true} path="/admin/company/users" component={UserManagement} />
                            <Route exact={true} path="/admin/company/groups" component={GroupManagement} />
                            <Route exact={true} path="/admin/company/settings" component={CompanySettings} />
                            <Route exact={true} path="/admin/company/payment" component={PaymentMethods} />
                            <Route exact={true} path="/admin/company/subscriptions" component={CompanySubscriptions} />
                            <Route exact={true} path="/admin/company/teamcontactcenter" component={TeamContactCenter} />
                            <Route exact={true} path="/admin/teamcontactcenter/account-manager" component={TeamContactCenterAccountManager} />
                            <Route exact={true} path="/admin/contractPro/orders-overview/" component={ContractProOrderOverview} />
                            <Route exact={true} path="/admin/contractPro/order-details/:orderId?" component={ContractProOrderDetails} />
                            <Route exact={true} path="/admin/company/approvals" component={ApprovalSettings} />
                            <Route exact={true} path="/admin/company/catalog" component={CustomCatalogRules} />
                            <Route exact={true} path="/admin/alert-management" component={AlertManagement} />
                            <Route exact={true} path="/admin/alert-management/edit" component={AlertManagementEdit} />
                            <Route exact={true} path="/admin/company/formulary" component={FormularyRules} />
                            <Route exact={true} path="/admin/company/formulary/rule/:id?" component={FormularyRuleDetail} />
                            <Route exact={true} path="/admin/company/formulary/draft/rule/:id?" component={FormularyRuleDetail} />
                            <Route exact={true} path="/admin/company/contracts" component={ManageContractsList} />
                            <Route exact={true} path="/admin/company/contract-details/:contractId?" component={ManageContracts} />
                            <Route exact={true} path="/admin/raven" component={RavenManagement} />
                            <Route exact={true} path="/admin/formulary" component={FormularyManagement} />
                            <Route exact={true} path="/admin/formulary/lists" component={FormularyLists} />
                            <Route exact={true} path="/admin/formulary/list/:id?" component={FormularyListDetail} />
                            <Route exact={true} path="/admin/formulary/drafts" component={AdminFormularyChangesGrid} />
                            <Route exact={true} path="/admin/formulary/test" component={TestFormulary} />
                            <Route exact={true} path="/admin/psm" component={ProductSubgroupManagement} />
                            <Route exact={true} path="/admin/apm" component={AutoPricingManagement} />
                            <Route exact={true} path="/admin/vsm" component={VendorShippingMarginManagement} />
                            <Route exact={true} path="/admin/vsm-add-edit" component={VendorShippingMarginManagementManageRule} />
                            <Route exact={true} path="/admin/rfq/service" component={RFQServiceManagement} />
                            <Route exact={true} path="/admin/rfq/pdp" component={RFQPDPManagement} />
                            <Route exact={true} path="/admin/rfq/header" component={RFQHeaderManagement} />
                            <Route exact={true} path="/admin/rfq" component={RFQManagement} />
                            <Route exact={true} path="/admin/onesource-oem-mapping" component={OneSourceOemMapping} />
                            <Route exact={true} path="/admin/site-settings" component={SiteSettings} />
                            <Route exact={true} path="/admin/rsimport" component={RSImportTool} />
                            <Route exact={true} path="/admin/lists" component={AdminLists} />
                            <Route exact={true} path="/admin/list/edit/:id" component={AdminSaveAdvancedList} />
                            <Route exact={true} path="/admin/list/add" component={AdminSaveAdvancedList} />
                            <Route exact={true} path="/admin/domainwhitelist" component={DomainWhiteListManagement} />
                            <Route exact={true} path="/admin/salesregions" component={SalesRegionManagement} />
                            <Route exact={true} path="/admin/rp" component={ReportingPortalManagement} />
                            <Route exact={true} path="/admin/rp/widget" component={RPWidget} />
                            <Route exact={true} path="/admin/rp/portal" component={RPPortal} />
                            <Route exact={true} path="/admin/rp/widget/add" component={RPWidgetAdd} />
                            <Route exact={true} path="/admin/rp/widget/edit/:id" component={RPWidgetAdd} />
                            <Route exact={true} path="/admin/rp/portal/add" component={RPPortalAdd} />
                            <Route exact={true} path="/admin/rp/Portal/edit/:id" component={RPPortalAdd} />
                            <Route exact={true} path="/admin/required-fields" component={RequiredFields} />
                            <Route exact={true} path="/admin/required-fields/vendor" component={RequiredFieldsVendor} />
                            <Route exact={true} path="/admin/required-fields/customer" component={RequiredFieldsCustomer} />
                            <Route exact={true} path="/admin/required-fields/invoice" component={RequiredFieldsInvoice} />
                            <Route exact={true} path="/admin/manage-invoice-fields" component={ManageInvoiceFields} />
                            <Route exact={true} path="/admin/manage-fields" component={ManageFields} />
                            <Route exact={true} path="/admin/vendor-cert" component={CertificationManagement} />
                            <Route exact={true} path="/admin/shoppingfeed/configuration" component={ShoppingFeedConfiguration} />
                            <Route exact={true} path="/admin/dataconfig" component={DataConfiguration} />
                            <Route exact={true} path="/admin/dataconfig/userlists" component={DataConfigurationList} />
                            <Route exact={true} path="/admin/dataconfig/partorders" component={DataConfigurationOrders} />
                            <Route exact={true} path="/admin/dataconfig/repairs" component={DataConfigurationRepairs} />
                            <Route exact={true} path="/admin/dataconfig/userlists/add" component={DataConfigurationListDetails} />
                            <Route exact={true} path="/admin/dataconfig/userlists/edit/:listId?" component={DataConfigurationListDetails} />
                            <Route exact={true} path="/admin/dataconfig/quotes" component={DataConfigurationQuotes} />
                            <Route exact={true} path="/admin/dataconfig/returns" component={DataConfigurationReturns} />
                            <Route exact={true} path="/admin/dataconfig/user-settings" component={DataConfigurationUserSettings} />
                            <Route exact={true} path="/admin/dataconfig/exchanges" component={DataConfigurationExchanges} />
                            <Route exact={true} path="/admin/dataconfig/loaners" component={DataConfiguration} />
                            <Route exact={true} path="/admin/dataconfig/quotedrepairs" component={DataConfigurationQuotedRepairs} />
                        </Switch>
                    </main>
                </div>
                <Footer isAdmin={true} />
                {
                    showContactDialog && (
                        <ContactSearchDialog
                            onSubmit={this.impersonateUser}
                            onCancel={() => this.setState({ showContactDialog: false })
                            }
                        />
                    )}
                {
                    showImpersonationError && (
                        <PopupAdmin
                            confirmText="Save"
                            cancelText="Cancel"
                            show={true}
                            hideButtons={true}
                            onCancel={() => this.setState({
                                showImpersonationError: false,
                                impersonationErrorMsg: '',
                            })}
                            disableClose={false}
                        >
                            <div>
                                {impersonationErrorMsg}
                            </div>
                        </PopupAdmin>
                    )
                }
            </div>
        );
    }
}

export default withRouter(AdminLayout);
